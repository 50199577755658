import { HttpClient } from "@angular/common/http";
import { inject, InjectionToken } from "@angular/core";
import { AngularEnvironment } from "@grainger/common/definitions/environment";
import { VersionJson } from "@grainger/common/definitions/version";
import { catchError, Observable, of, take, tap } from "rxjs";
import { sharedEnvironment } from "../environment/shared-environment";

export class InitUtil {
    static APP_ENVIRONMENT = new InjectionToken<AngularEnvironment>("AppEnvironment");

    static init() {
        const httpClient = inject(HttpClient);
        const appEnvironment = inject(InitUtil.APP_ENVIRONMENT);

        return InitUtil.getVersionInfo(httpClient).pipe(
            take(1),
            tap((versionInfo) => {
                let { version, buildNumber, environment } = versionInfo;
                version = version || "unknown";
                buildNumber = buildNumber || "development";
                environment = environment || "development";

                appEnvironment.version = version;
                appEnvironment.buildNumber = buildNumber;
                appEnvironment.environment = environment;

                console.log(`****** VERSION: ${version} ******`);
                console.log(`****** BUILD: ${buildNumber} ******`);
                console.log(`****** ENVIRONMENT: ${environment} ******`);

                if (environment === "production") {
                    console.log("****** PRODUCTION MODE ******");
                }
            })
        );
    }

    /**
     * Get version info from root directory
     */
    static getVersionInfo(httpClient: HttpClient): Observable<VersionJson> {
        return httpClient
            .get<VersionJson>("version.json", {
                responseType: "json"
            })
            .pipe(
                catchError((err, thrown) => {
                    console.error(err);
                    console.error("Unable to retrieve version.json");

                    const { version, buildNumber, environment } = sharedEnvironment;
                    return of({
                        version,
                        buildNumber,
                        environment
                    });
                })
            );
    }
}
