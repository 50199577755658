import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CheckboxStatus } from "../../core/definitions/checkbox";

@Component({
    selector: "grainger-checkbox",
    imports: [CommonModule],
    templateUrl: "./checkbox.component.html",
    styleUrl: "./checkbox.component.scss"
})
export class CheckboxComponent {
    @Output() selectionChanged: EventEmitter<CheckboxStatus> = new EventEmitter<CheckboxStatus>();

    @Input() label: string;
    @Input() value: string;

    handleSelectionChange(event: Event): void {
        const target = event.target as HTMLInputElement;

        this.selectionChanged.emit({
            label: this.label,
            key: this.value,
            checked: target.checked
        });
    }
}
