import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { BaseTabComponent } from "../components/for-extension-only/base-tab/base-tab.component";
import { IconListItemComponent } from "../components/icon-list-item/icon-list-item.component";
import { MobileDepartmentHeaderComponent } from "../components/mobile-department-header/mobile-department-header.component";
import { CustomAnimations } from "../core/animations/animations";

@Component({
    selector: "grainger-resources",
    imports: [CommonModule, IconListItemComponent, MobileDepartmentHeaderComponent],
    templateUrl: "./resources.component.html",
    styleUrl: "./resources.component.scss",
    animations: [CustomAnimations.fadeIn("0.3s")]
})
export class ResourcesComponent extends BaseTabComponent {}
