import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { IPillar } from "@grainger/common/definitions/pillar";
import { UpdatePillarDto } from "@grainger/common/dto/update-pillar.dto";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class PillarService {
    private readonly http = inject(HttpClient);

    public updatePillar(id: string, pillarDto: UpdatePillarDto): Observable<IPillar> {
        return this.http.patch<IPillar>(`${environment.apiBaseUrl}/pillars/${id}`, pillarDto);
    }
}
