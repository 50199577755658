import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { catchError, filter, take } from "rxjs";
import { ArrowButtonComponent } from "../../components/arrow-button/arrow-button.component";
import { BaseAuthFormComponent } from "../../components/base-auth-form/base-auth-form.component";
import { FormControlComponent } from "../../components/form-control/form-control.component";
import { FormPageWrapperComponent } from "../../components/form-page-wrapper/form-page-wrapper.component";
import { InputDirective } from "../../core/directives/input.directive";
import { CustomValidators } from "../../core/validators/custom-validators.validator";

@Component({
    selector: "grainger-login",
    standalone: true,
    imports: [
        CommonModule,
        FormPageWrapperComponent,
        ArrowButtonComponent,
        ReactiveFormsModule,
        InputDirective,
        FormControlComponent,
        RouterModule
    ],
    templateUrl: "./login.component.html",
    styleUrl: "./login.component.scss"
})
export class LoginComponent extends BaseAuthFormComponent implements OnInit {
    private router = inject(Router);

    override ngOnInit(): void {
        super.ngOnInit();

        this.analyticsService.trackPageView({ pageTitle: "Login" });
    }

    protected initForm() {
        return this.fb.group({
            email: this.fb.control(null, [Validators.required, CustomValidators.email]),
            password: this.fb.control(null, [Validators.required])
        });
    }

    public logIn(): void {
        if (this.isLoading) {
            return;
        }

        this.trackClick("Clicked log in", "Login Page");

        this.hasSubmissionError = false;

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.updateValueAndValidity();

            return;
        }

        this.authService
            .login(this.email.value || "", this.password.value || "")
            .pipe(
                catchError((err) => {
                    this.hasSubmissionError = true;
                    this.submissionError = err.message || this.submissionError;
                    throw Error("Error logging in: " + err);
                })
            )
            .subscribe(() => {
                console.log("Logged in successfully");
                this.authService.user$.pipe(filter(Boolean), take(1)).subscribe(() => {
                    setTimeout(() => {
                        console.log("Navigating to home page");

                        this.router.navigate(["/"]);
                    }, 0);
                });
            });
    }

    private get email(): FormControl {
        return this.form.get("email") as FormControl;
    }

    private get password(): FormControl {
        return this.form.get("password") as FormControl;
    }
}
