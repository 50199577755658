import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { AuthService } from "../services/auth.service";

export class AuthGuard {
    static canActivateAuthenticated: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const authService = inject(AuthService);
        const router = inject(Router);

        return authService.isAuthenticated$.pipe(
            switchMap((isAuthenticated) => {
                if (!isAuthenticated) {
                    throw new Error("User must be authenticated to access this route");
                }

                return of(true);
            }),
            catchError((err) => {
                // navigate to login page as user is not authenticated
                router.navigate(["/auth", "login"]);
                return of(false);
            })
        );
    };

    static canActivateUnauthenticated: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const authService = inject(AuthService);
        const router = inject(Router);

        return authService.isAuthenticated$.pipe(
            map((isAuthenticated) => !isAuthenticated),
            tap((isNotAuthenticated) => {
                if (!isNotAuthenticated) {
                    // navigate to home page
                    router.navigate(["/"]);
                }
            })
        );
    };

    static canActivateAsAdmin: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const authService = inject(AuthService);
        const router = inject(Router);

        return authService.isAdmin$.pipe(
            tap((isAdmin) => {
                if (!isAdmin) {
                    console.error("Forbidden route - current user is not an Admin");
                    // navigate to home page
                    router.navigate(["/"]);
                }
            })
        );
    };

    static canActivateAsSuperadmin: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const authService = inject(AuthService);
        const router = inject(Router);

        return authService.isSuperAdmin$.pipe(
            tap((isAdmin) => {
                if (!isAdmin) {
                    console.error("Forbidden route - current user is not a Superadmin");
                    // navigate to home page
                    router.navigate(["/"]);
                }
            })
        );
    };
}
