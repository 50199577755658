import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, ReactiveFormsModule } from "@angular/forms";
import { ISupplier } from "@grainger/common/definitions/supplier";
import { SupplierFilters } from "@grainger/common/definitions/supplier-filters";
import { PageMetaDto } from "@grainger/common/dto/page-meta.dto";
import { PageDto } from "@grainger/common/dto/page.dto";
import { debounceTime } from "rxjs";
import { AccordionItemComponent } from "../../components/accordion-item/accordion-item.component";
import { ArrowButtonComponent } from "../../components/arrow-button/arrow-button.component";
import { CheckboxComponent } from "../../components/checkbox/checkbox.component";
import { IconListItemComponent } from "../../components/icon-list-item/icon-list-item.component";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { SearchBarComponent } from "../../components/search-bar/search-bar.component";
import { SubpageHeaderComponent } from "../../components/subpage-header/subpage-header.component";
import { SupplierAccordionComponent } from "../../components/supplier-accordion/supplier-accordion.component";
import { CheckboxGroup, CheckboxStatus } from "../../core/definitions/checkbox";
import { CapitalizeFirstLetterPipe } from "../../core/pipes/capitalize-first-letter.pipe";
import { AnalyticsService } from "../../core/services/analytics.service";
import { SupplierService } from "../../core/services/supplier.service";

@Component({
    selector: "grainger-suppliers",
    imports: [
        CommonModule,
        SubpageHeaderComponent,
        SupplierAccordionComponent,
        AccordionItemComponent,
        CheckboxComponent,
        SearchBarComponent,
        PaginationComponent,
        ReactiveFormsModule,
        CapitalizeFirstLetterPipe,
        IconListItemComponent,
        ArrowButtonComponent
    ],
    templateUrl: "./suppliers.component.html",
    styleUrl: "./suppliers.component.scss"
})
export class SuppliersComponent implements AfterViewInit {
    private supplierService = inject(SupplierService);
    private changeDetector = inject(ChangeDetectorRef);
    private fb = inject(FormBuilder);
    private analyticsService = inject(AnalyticsService);

    public suppliers: ISupplier[] = [];
    public showFilters: boolean = true;
    public currentPage: number = 1;

    public searchMetaData: PageMetaDto | null = null;

    public searchControl: FormControl = new FormControl("");

    public filterForm: ReturnType<SuppliersComponent["initFilterForm"]>;
    public filterGroupNames: string[];
    public filterGroups: {
        title: string;
        controls: { name: string; value: string }[];
    }[];

    // Used only for analytics human readable names
    private selectedDepartmentFilterLabels: Set<string> = new Set<string>();

    private readonly DEBOUNCE_TIME = 400;

    ngAfterViewInit(): void {
        this.analyticsService.trackPageView({ pageTitle: "Suppliers Page" });

        this.updateSearch("");

        this.searchControl.valueChanges.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe((search) => {
            this.updateSearch(search);
        });
    }

    private initFilterForm() {
        return this.fb.group({
            industry: this.fb.array([]),
            type: this.fb.array([]),
            department: this.fb.array([])
        });
    }

    public toggleFilters(): void {
        this.trackClick(`Clicked ` + (this.showFilters ? `hide filters` : `show filters`));

        this.showFilters = !this.showFilters;
    }

    public updateFilters(groupId: string, checkbox: CheckboxStatus): void {
        const filterList = this.filterForm.get(groupId) as FormArray;

        const checked = checkbox.checked;

        const foundIndex = filterList.controls.findIndex((control) => {
            return control.value === checkbox.key;
        });

        if (checked) {
            if (foundIndex === -1) {
                filterList.push(new FormControl(checkbox.key));

                if (groupId === "department") {
                    this.trackClick(`Add ${groupId} filter - ${checkbox.label}`);
                    this.selectedDepartmentFilterLabels.add(checkbox.label);
                }
            }
        } else {
            filterList.removeAt(foundIndex);
            this.trackClick(`Remove ${groupId} filter - ${checkbox.label}`);

            if (groupId === "department") {
                this.selectedDepartmentFilterLabels.delete(checkbox.label);
            }
        }

        this.supplierService
            .searchSuppliers({
                search: this.searchControl.value,
                page: 1,
                filters: {
                    industry: this.industryFilters.value,
                    type: this.typeFilters.value,
                    department: this.departmentFilters.value
                }
            })
            .subscribe((res) => {
                this.updateUi(res);
            });
    }

    public updateSearch(search: string): void {
        this.supplierService.searchSuppliers({ search, page: 1 }).subscribe((res) => {
            this.updateUi(res);
            this.filterGroupNames = Object.keys(res?.filters || {});

            if (res?.filters) {
                this.filterGroups = this.generateCheckboxes(res?.filters);
            }

            this.filterForm = this.initFilterForm();

            this.analyticsService.trackInteraction(
                {
                    action: "search",
                    category: "form",
                    location: "Suppliers Page",
                    label: `Searched for: ${search}`
                },
                {
                    search_term: search || null
                }
            );
        });
    }

    public trackClick(label: string): void {
        this.analyticsService.trackInteraction(
            {
                action: "click",
                category: "button",
                location: "Suppliers Page",
                label
            },
            {
                search_term: this.searchControl.value || null,
                supplier_industry_filters: this.formatFiltersForAnalytics(this.industryFilters.value),
                supplier_type_filters: this.formatFiltersForAnalytics(this.typeFilters.value),
                supplier_department_filters: this.formatFiltersForAnalytics(
                    Array.from(this.selectedDepartmentFilterLabels)
                ),
                result_page_number: this.currentPage
            }
        );
    }

    public trackSupplierBy(index: number, supplier: ISupplier): string {
        return supplier.name;
    }

    private generateCheckboxes(filters: SupplierFilters): CheckboxGroup[] {
        const controls: {
            title: string;
            controls: { name: string; value: string }[];
        }[] = [];

        controls.push({
            title: "industry",
            controls: filters?.industry.map((name) => ({ name, value: name })) || []
        });
        controls.push({
            title: "type",
            controls: filters?.type.map((name) => ({ name, value: name })) || []
        });
        controls.push({
            title: "department",
            controls:
                filters?.department.map(({ name, id }) => ({
                    name,
                    value: id
                })) || []
        });

        return controls;
    }

    private formatFiltersForAnalytics(filters: string[]) {
        if (filters.length > 0) {
            return filters.join(", ");
        }

        return "No filters selected";
    }

    public updatePagination(page: number): void {
        this.trackClick(`Updated result page - ${page}`);
        this.supplierService
            .searchSuppliers({
                search: this.searchControl.value,
                filters: {
                    industry: this.industryFilters.value,
                    type: this.typeFilters.value,
                    department: this.departmentFilters.value
                },
                page
            })
            .subscribe((res) => {
                this.updateUi(res);
            });
    }

    private updateUi(res: PageDto<ISupplier> | null): void {
        this.suppliers = res?.data ?? [];
        this.searchMetaData = res?.meta ?? null;

        this.changeDetector.detectChanges();
        this.resetScroll();
    }

    private resetScroll() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    private get industryFilters(): FormArray {
        return this.filterForm.get("industry") as FormArray;
    }

    private get typeFilters(): FormArray {
        return this.filterForm.get("type") as FormArray;
    }

    private get departmentFilters(): FormArray {
        return this.filterForm.get("department") as FormArray;
    }
}
