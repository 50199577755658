import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { UserProfile } from "@grainger/common/definitions/user";
import { environment } from "../../../environments/environment";
import { AnalyticsPageView, TrackedInteraction } from "../definitions/tracked-interaction";

declare const window: any;

@Injectable({ providedIn: "root" })
export class AnalyticsService {
    private renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
        private titleService: Title
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public trackPageView(interaction: AnalyticsPageView, extraArgs?: Record<string, any>): void {
        const { pageTitle, label } = interaction;
        this.titleService.setTitle("Grainger - " + pageTitle);

        setTimeout(() => {
            window.gtag("event", "page_view", {
                category: "page_view",
                page_title: pageTitle,
                label: label ?? `${pageTitle} Page Viewed`,
                page_location: window.location.href,
                ...extraArgs,
                ...this.getMetaData()
            });
        }, 300);
    }

    public trackInteraction(interaction: TrackedInteraction, extraArgs?: Record<string, any>): void {
        const { action, category, label, location } = interaction;

        window.gtag("event", action, {
            category: category,
            label,
            location,
            ...extraArgs,
            ...this.getMetaData()
        });
    }

    public getMetaData(): Record<string, any> {
        return {};
    }

    public initializeGoogleAnalytics(): void {
        const linkScript = this.renderer.createElement("script");
        this.renderer.setAttribute(linkScript, "async", "async");
        const startComment = this.renderer.createComment("Begin snippet from service");
        const endComment = this.renderer.createComment("End snippet from service");
        linkScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaId}`;

        // initialiaze GA
        const initializationScript = this.renderer.createElement("script");
        initializationScript.type = "text/javascript";
        initializationScript.text = ` 
    window.dataLayer = window.dataLayer || []; 
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag("js", new Date());
    `;

        this.renderer.appendChild(this.document.head, startComment);
        this.renderer.appendChild(this.document.head, linkScript);
        this.renderer.appendChild(this.document.head, initializationScript);
        this.renderer.appendChild(this.document.head, endComment);
    }

    public setUser(user: UserProfile | null): void {
        const userId = user?.id ?? null;
        window.gtag("config", environment.gaId, { send_page_view: false, user_id: userId });
        window.gtag("set", "user_data", { email: user?.email ?? null });
        window.gtag("set", "user_properties", {
            sales_region_function: user?.regionOrFunction ?? null,
            district_team: user?.district ?? null
        });
        window.gtag("event", "user_changed", {
            user_id: userId
        });
    }
}
