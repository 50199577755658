import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { AuthService } from "@grainger/angular/services/auth.service";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { AnalyticsService } from "./core/services/analytics.service";
import { LoadingService } from "./core/services/loading.service";

@Component({
    imports: [CommonModule, RouterModule, LoadingSpinnerComponent],
    standalone: true,
    selector: "grainger-root",
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss"
})
export class AppComponent implements OnInit {
    title = "Grainger Facilities";

    private router = inject(Router);
    private loadingService = inject(LoadingService);
    private analyticsService = inject(AnalyticsService);
    private authService = inject(AuthService);

    public isLoading: boolean = false;

    ngOnInit(): void {
        this.analyticsService.initializeGoogleAnalytics();

        this.loadingService.getLoadingStatus().subscribe((isLoading) => {
            this.isLoading = isLoading;
        });
        this.router.events.subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            // Scroll to top of page on navigation
            if (window.scrollY > 0) {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        });

        this.authService.user$.subscribe((user) => {
            this.analyticsService.setUser(user);
        });
    }
}
