import { CommonModule } from "@angular/common";
import { Component, DestroyRef, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AuthService } from "@grainger/angular/services/auth.service";
import { IUser } from "@grainger/common/definitions/user";
import { AnalyticsService } from "../../core/services/analytics.service";
import { LoadingService } from "../../core/services/loading.service";

@Component({
    selector: "grainger-base-auth-form",
    imports: [CommonModule, RouterModule, ReactiveFormsModule],
    templateUrl: "./base-auth-form.component.html",
    styleUrl: "./base-auth-form.component.scss"
})
export abstract class BaseAuthFormComponent implements OnInit {
    protected fb = inject(FormBuilder);
    protected authService = inject(AuthService);
    protected loadingService = inject(LoadingService);
    protected destroy$ = inject(DestroyRef);
    protected analyticsService = inject(AnalyticsService);

    public form!: FormGroup;

    public isLoading: boolean = false;
    public hasSubmissionError: boolean = false;
    public submissionError: string = "There was an error, please try again in a few moments.";

    ngOnInit(): void {
        this.loadingService
            .getLoadingStatus()
            .pipe(takeUntilDestroyed(this.destroy$))
            .subscribe((isLoading) => {
                this.isLoading = isLoading;
            });

        this.form = this.initForm();
    }

    protected abstract initForm(user?: IUser): FormGroup;

    public trackClick(label: string, location: string): void {
        this.analyticsService.trackInteraction({
            action: "click",
            location: location,
            category: "button",
            label
        });
    }
}
