import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { finalize } from "rxjs";
import { LoadingService } from "../services/loading.service";

export const loadingInterceptor: HttpInterceptorFn = (request, next) => {
    const loadingService = inject(LoadingService);
    loadingService.setLoadingStatus(true);

    return next(request).pipe(
        finalize(() => {
            setTimeout(() => {
                loadingService.setLoadingStatus(false);
            }, 0);
        })
    );
};
