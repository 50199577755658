import { CommonModule } from "@angular/common";
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
    ViewChild,
    inject
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuthService } from "@grainger/angular/services/auth.service";
import { ILinkableResource } from "@grainger/common/definitions/common";
import { DropdownMenu } from "../../core/definitions/dropdown-menu";
import { EditService } from "../../core/services/edit.service";
import { ArrowButtonComponent } from "../arrow-button/arrow-button.component";
import { DropdownNavComponent } from "../dropdown-nav/dropdown-nav.component";
import { EditModePopoverComponent } from "../edit-mode-popover/edit-mode-popover.component";

@Component({
    selector: "grainger-subpage-header",
    standalone: true,
    imports: [CommonModule, ArrowButtonComponent, DropdownNavComponent, EditModePopoverComponent],
    templateUrl: "./subpage-header.component.html",
    styleUrl: "./subpage-header.component.scss"
})
export class SubpageHeaderComponent implements AfterViewChecked, AfterViewInit {
    @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() dropdownClicked: EventEmitter<string> = new EventEmitter<string>();

    @Input() button: ILinkableResource | null = null;
    @Input() dropdownMenu?: DropdownMenu;
    @Input() hasFloatingButton: boolean = false;
    @Input() hasEditModeToggle: boolean = false;
    @Input() floatWithElement: ElementRef<HTMLElement> | null = null;

    @ViewChild("floatingButton", { static: false })
    floatingButton: ElementRef<HTMLElement> | null;

    public editModePopoverVisible: boolean = false;
    public canShowFloatingButton: boolean = false;
    public isEditing: boolean = false;
    public isAdmin: boolean = false;

    private readonly INLINE_THRESHOLD: number = 1;

    //Dependencies
    private changeDetectorRef = inject(ChangeDetectorRef);
    private renderer = inject(Renderer2);
    private destroyRef = inject(DestroyRef);
    private editService = inject(EditService);
    private auth = inject(AuthService);

    @HostListener("window:scroll", ["$event"])
    onWindowScroll(event: Event): void {
        if (this.hasFloatingButton && this.floatingButton && this.floatWithElement) {
            this.handleScroll(this.floatWithElement);
        }
    }

    ngAfterViewInit(): void {
        this.editService.isEditing$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((canEdit) => {
            this.isEditing = canEdit;
        });
        this.editService.editModePopoverVisible$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isVisible) => {
            this.editModePopoverVisible = isVisible;
        });

        this.auth.isAdmin$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });
    }

    ngAfterViewChecked(): void {
        this.checkIfFloatingButtonPresent();
    }

    public handleButtonClick(): void {}

    private checkIfFloatingButtonPresent(): void {
        if (this.canShowFloatingButton !== !!this.floatingButton) {
            this.changeDetectorRef.detectChanges();
        }
        this.canShowFloatingButton = !!this.floatingButton;
    }

    private handleScroll(element: ElementRef<HTMLElement> | null): void {
        if (!element || !this.floatingButton) {
            return;
        }

        const el = element.nativeElement;

        const top = parseInt(window.getComputedStyle(el).top, 10);

        const currentButtonTop = element.nativeElement.getBoundingClientRect().top;

        if (Math.abs(currentButtonTop - top) <= this.INLINE_THRESHOLD) {
            this.renderer.addClass(this.floatingButton.nativeElement, "aligned-with-container");
        } else {
            this.renderer.removeClass(this.floatingButton.nativeElement, "aligned-with-container");
        }
    }

    public toggleEditMode(): void {
        this.editService.toggleEditMode();
        if (!this.editModePopoverVisible && this.isEditing) {
            this.editService.toggleEditModePopover();
        }
    }
}
