import { AngularEnvironment } from "@grainger/common/definitions/environment";

export const sharedEnvironment: AngularEnvironment = {
    production: false,
    apiBaseUrl: "/api",
    version: "unknown",
    buildNumber: "development",
    environment: "development",
    gaId: ""
};
