import { Injectable, inject } from "@angular/core";
import { AuthService } from "@grainger/angular/services/auth.service";
import { BehaviorSubject, Observable, combineLatest, map, take, tap } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class EditService {
    private readonly auth = inject(AuthService);

    private readonly _editMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly editMode$: Observable<boolean> = this._editMode.asObservable();
    public readonly isEditing$: Observable<boolean> = combineLatest([this.editMode$, this.auth.isAdmin$]).pipe(
        map(([editMode, isAdmin]) => editMode && isAdmin)
    );
    private readonly _editModePopoverVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly editModePopoverVisible$: Observable<boolean> = this._editModePopoverVisible$.asObservable();

    public toggleEditMode(): void {
        this.auth.isAdmin$
            .pipe(
                take(1),
                tap((isAdmin) => {
                    if (!isAdmin) {
                        this._editMode.next(false);
                        throw new Error("User is not authorized to edit");
                    }
                    this._editMode.next(!this._editMode.value);
                })
            )
            .subscribe();
    }

    public disableEditMode(): void {
        this._editMode.next(false);
    }

    public toggleEditModePopover(): void {
        this._editModePopoverVisible$.next(!this._editModePopoverVisible$.value);
    }
}
