import { ExtraOptions, Route } from "@angular/router";
import { AuthGuard } from "@grainger/angular/guards/auth.guard";
import { DepartmentSectionSelectorComponent } from "./components/department-section-selector/department-section-selector.component";
import { MobileDepartmentLandingGuard } from "./core/guards/mobile-department-landing.guard";
import { ProfileGuard } from "./core/guards/profile.guard";
import { ResetPasswordGuard } from "./core/guards/reset-password.guard";
import { DepartmentResolver } from "./core/resolvers/department.resolver";
import { ChallengesComponent } from "./dept-tabs/challenges.component";
import { EmbedsComponent } from "./dept-tabs/embeds.component";
import { InsightsComponent } from "./dept-tabs/insights.component";
import { OverviewComponent } from "./dept-tabs/overview.component";
import { ResourcesComponent } from "./dept-tabs/resources.component";
import { SolutionsComponent } from "./dept-tabs/solutions.component";
import { CompleteProfileComponent } from "./pages/complete-profile.component";
import { DepartmentLandingComponent } from "./pages/department-landing/department-landing.component";
import { FacilityComponent } from "./pages/facility/facility.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { LoginComponent } from "./pages/login/login.component";
import { RegisterComponent } from "./pages/register/register.component";
import { SuppliersComponent } from "./pages/suppliers/suppliers.component";
import { UpdatePasswordComponent } from "./pages/update-password/update-password.component";

export const routerExtraOptions: ExtraOptions = {
    scrollPositionRestoration: "top"
};

export const appRoutes: Route[] = [
    {
        path: "",
        component: FacilityComponent,
        canActivate: [AuthGuard.canActivateAuthenticated, ProfileGuard.isValid]
    },
    {
        path: "auth/login",
        redirectTo: "login"
    },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [AuthGuard.canActivateUnauthenticated]
    },
    {
        path: "register",
        component: RegisterComponent,
        canActivate: [AuthGuard.canActivateUnauthenticated]
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        canActivate: [AuthGuard.canActivateUnauthenticated]
    },
    {
        path: "complete-profile",
        component: CompleteProfileComponent,
        canActivate: [AuthGuard.canActivateAuthenticated]
    },
    {
        path: "reset-password",
        component: UpdatePasswordComponent,
        canActivate: [ResetPasswordGuard.canActivate]
    },
    {
        path: "home",
        component: FacilityComponent,
        canActivate: [AuthGuard.canActivateAuthenticated, ProfileGuard.isValid]
    },
    {
        path: "suppliers",
        component: SuppliersComponent,
        canActivate: [AuthGuard.canActivateAuthenticated, ProfileGuard.isValid]
    },
    {
        path: "department/:id",
        component: DepartmentLandingComponent,
        resolve: { department: DepartmentResolver.getDepartment },
        runGuardsAndResolvers: "always",
        canActivate: [AuthGuard.canActivateAuthenticated, ProfileGuard.isValid],
        children: [
            {
                path: "",
                component: DepartmentSectionSelectorComponent,
                canActivate: [MobileDepartmentLandingGuard.canActivate]
            },
            {
                path: "overview",
                component: OverviewComponent
            },
            {
                path: "challenges",
                component: ChallengesComponent
            },
            {
                path: "solutions",
                component: SolutionsComponent
            },
            {
                path: "embeds",
                component: EmbedsComponent
            },
            {
                path: "resources",
                component: ResourcesComponent
            },
            {
                path: "insights",
                component: InsightsComponent
            }
        ]
    },

    { path: "**", redirectTo: "" }
];
