import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import { ApplicationConfig, provideAppInitializer } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withRouterConfig } from "@angular/router";
import { authInterceptor } from "@grainger/angular/interceptors/auth.interceptor";
import { tokenInterceptor } from "@grainger/angular/interceptors/token.interceptor";
import { InitUtil } from "@grainger/angular/util/init.util";
import { environment } from "../environments/environment";
import { appRoutes, routerExtraOptions } from "./app.routes";
import { loadingInterceptor } from "./core/interceptors/loading.interceptor";

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: InitUtil.APP_ENVIRONMENT, useFactory: () => environment },
        provideAppInitializer(InitUtil.init),
        provideRouter(appRoutes, withRouterConfig(routerExtraOptions)),
        provideAnimations(),
        provideHttpClient(withFetch(), withInterceptors([tokenInterceptor, authInterceptor, loadingInterceptor]))
    ]
};
